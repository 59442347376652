import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import EnConstruccionImg from "C:/Users/cascada/Desktop/React/citmco/citmco-app/src/assets/EnConstruccion.png";

export default function () {
    return (
        <Container>
            <Row>
                <Col>
                    <Card style={{ width: '100%', border: 'none' }}>
                        <Card.Body>
                            <h1 style={{ fontWeight: "initial" }}>CONTACTO</h1>
                            <img src={EnConstruccionImg} className="img-fluid" alt="En Construcci�n"></img>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        );
}