import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav } from "react-bootstrap";
import EnConstruccion from './sections/EnConstruccion';
import Footer from './components/Footer';
import logo from './logo.svg';
import './App.css';
import Home from './sections/Home';
import Empresa from './sections/Empresa';
import Servicios from './sections/Servicios';
import Proyectos from './sections/Proyectos';
import Blog from './sections/Blog';
import Contacto from './sections/Contacto';

function App() {
    const navEnum = {
        "Home": 1,
        "Empresa": 2,
        "Servicios": 3,
        "Proyectos": 4,
        "Blog": 5,
        "Contacto": 6
    }

    const [stateNavMenu, setNavMenu] = useState(navEnum.Home);

    function navbarAction(value) {
        setNavMenu(value);
    }

    return (
        <div className="App">
            <Navbar className="navbar-custom" variant="dark" fixed="top" expand="lg" >
                <Navbar.Brand href="#home" onClick={() => navbarAction(navEnum.Home)}><img height="38" width="55" src="https://citmco.com.mx/img/empresa/logo2citmco.png"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {stateNavMenu == navEnum.Home ? 
                            <Nav.Link href="#home" style={{color:"#fff"}} onClick={() => navbarAction(navEnum.Home)} >Inicio</Nav.Link>    
                            :
                            <Nav.Link href="#home" onClick={() => navbarAction(navEnum.Home)} >Inicio</Nav.Link>
                        }
                        <Nav.Link style={{ color:"#0033FF"}} href="#empresa" onClick={() => navbarAction(navEnum.Empresa)}>Empresa</Nav.Link>
                        <Nav.Link href="#servicios" onClick={() => navbarAction(navEnum.Servicios)}>Servicios</Nav.Link>
                        <Nav.Link href="#proyectos" onClick={() => navbarAction(navEnum.Proyectos)}>Proyectos</Nav.Link>
                        <Nav.Link href="#blog" onClick={() => navbarAction(navEnum.Blog)}>Blog</Nav.Link>
                        <Nav.Link href="#contacto" onClick={() => navbarAction(navEnum.Contacto)}>Contacto</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <br />
            {stateNavMenu == navEnum.Home ? <Home /> : null}
            {stateNavMenu == navEnum.Empresa ? <Empresa /> : null}
            {stateNavMenu == navEnum.Servicios ? <Servicios /> : null}
            {stateNavMenu == navEnum.Proyectos ? <Proyectos /> : null}
            {stateNavMenu == navEnum.Blog ? <Blog /> : null}
            {stateNavMenu == navEnum.Contacto ? <Contacto /> : null}

        </div>
    );
}

export default App;
