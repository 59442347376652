import React from 'react';

export default function () {
    return (
		<footer className="footer-distributed">
			<div className="footer-left">
				<h3><span>CITMCO</span></h3>
				<p className="footer-links">
					<a href="#">Inicio</a>
					-
					<a href="#">Empresa</a>
					-
					<a href="#">Servicios</a>
					-
					<a href="#">Proyectos</a>
					-
					<a href="#">Blog</a>
					-
					<a href="#">Contactos</a>
				</p>
				<p className="footer-company-name"><span className="far fa-copyright"></span> 2020. All Rights Reserved.</p>
			</div>

			<div className="footer-center">
				<div>
					<i className="fa fa-map-marker"></i>
					<p><span>Guerrero #1004, Col Centro.</span>Coatzacoalcos, Veracruz</p>
				</div>
				<div>
					<i className="fa fa-phone"></i>
					<p>(921) 24 37757</p>
				</div>
				<div>
					<i className="fa fa-envelope"></i>
					<p><a href="mailto:support@company.com">support@company.com</a></p>
				</div>
			</div>

			<div className="footer-right">
				<p className="footer-company-about">
					<span>About the company</span>
					Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus vehicula sit amet.
				</p>
				<div className="footer-icons">
					<a href="#"><i className="fab fa-facebook-f"></i></a>
					<a href="#"><i className="fab fa-twitter"></i></a>					
					<a href="#"><i className="fab fa-instagram"></i></a>
					<a href="#"><i className="fab fa-linkedin-in"></i></a>
				</div>
			</div>
		</footer>
        );
}