import React, { useState } from 'react';
import { Card, Row, Col, Container, Carousel } from 'react-bootstrap';

export default function () {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Container>
            <Row>
                <Col>
                    <Card style={{ width: '100%', border: 'none' }}>
                        <Carousel activeIndex={index} onSelect={handleSelect}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://citmco.com.mx/img/home/Carousel2.jpg"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://citmco.com.mx/img/home/Carousel3.jpg"
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://citmco.com.mx/img/home/Carousel5.jpg"
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                        </Carousel>

                        <Card.Body>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }}>
                                    <br />
                                    <h1 style={{ fontWeight: "bold", color: "#0033FF" }}>MISIÓN</h1>
                                    <br />
                                    <p className="fade-in" style={{ textAlign: "justify" }}>
                                        Desarrollar y construir de Proyectos de infraestructuras terrestre, portuarias, cimentación profunda y obra civil en general contando con profesionales de amplia experiencia brindando a nuestros clientes soluciones integrales de calidad basadas en la creatividad, ingeniería y la ética profesional, garantizando la salud y la integridad física de nuestros trabajadores, así como la conservación del medio ambiente.
                                    </p>
                                    <br /><br/>
                                    <h1 style={{ fontWeight: "bold", color: "#0033FF" }}>VISIÓN</h1>
                                    <br />
                                    <p className="fade-in" style={{textAlign: "justify" }}>
                                        Ser reconocidos como la Empresa Mexicana referente en la construcción de infraestructuras terrestre, portuarias, cimentación profunda y obra civil en general con destacada presencia en la República Mexicana, que garantice servicios de alta calidad y seguridad con estándar internacional a través de los cuales se logre satisfacer las expectativas de clientes, colaboradores, accionistas y comunidad.
                                    </p>
                                    <br /><br />
                                    <h1 style={{ fontWeight: "bold", color: "#0033FF" }}>POLÍTICA AMBIENTAL</h1>
                                    <br />
                                    <p className="fade-in" style={{
                                        textAlign: "justify"
                                    }}>
                                        CITMCO se compromete con la mejora continua de la gestión ambiental en todos los niveles, negocios y locales de la empresa. Su visión es ser una empresa que aplica los principios de la gestión ambiental para lograr la mejora ambiental continua y el desarrollo sostenible. En este espíritu, hemos adoptado la siguiente política ambiental: La realización de negocios de CITMCO con el fin de promover la calidad del
                                        medio ambiente.
                                    </p> 
                                </Col>
                            </Row>
                            <br />
                            <Card.Title style={{ fontWeight: "bold", color: "#0033FF" }}>POLITICA DE SEGURIDAD</Card.Title>
                            <br />
                            <Row>
                                <Col>
                                    <Card style={{ width: '100%', border:'none' }}>
                                        <Card.Img variant="top" src="https://citmco.com.mx/img/home/Carousel4.jpg" />
                                        <Card.Body>
                                            <Card.Title>Nuestra visión</Card.Title>
                                            <Card.Text className="fade-in" style={{textAlign:"justify"}}>
                                                En CITMCO estamos comprometidos en lograr una Cultura de Accidente Cero mediante la integración de la Seguridad en todas las instalaciones y negocios de la Compañía a través de un proceso de mejora continua. Nuestro objetivo es ser una empresa sin lesiones ni enfermedades relacionadas con el trabajo.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ width: '100%', border: 'none' }}>
                                        <Card.Img variant="top" src="https://citmco.com.mx/img/home/Carousel1.jpg" />
                                        <Card.Body>
                                            <Card.Title>Nuestro compromiso.</Card.Title>
                                            <Card.Text className="fade-in" style={{ textAlign: "justify" }}>
                                                La base de nuestra política es la prevención de accidentes fatales y lesiones de colaboradores, contratistas y visitas. Todos nuestros procesos, productos y servicios cumplirán o excederán todos los requisitos legales de Higiene y Seguridad Industrial aplicables.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }}>
                                   
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}