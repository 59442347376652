import React from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import Card1 from "C:/Users/cascada/Desktop/React/citmco/citmco-app/src/assets/architecture-768432_1920.jpg";

export default function Proyectos() {
    const jsonProjects = {
        "Proyectos": [
            {
                "titulo": "LEVANTAMIENTO BIM AS-DESIGN DE CENTRO LOGÍSTICO PARA PROLOGIS",
                "cliente": "NORTON ",
                "servicio": "MODELADO Y COORDINACIÓN BIM DE TODAS LAS DISCIPLINAS ",
                "fecha": "2016",
                "imagen": "https://citmco.com.mx/img/architect-3979490.jpg",
            },
            {
                "titulo": 'INGENIERÍA DE INSTALACIONES PARA EL PROYECTO RESIDENCIAL “GREEN PARK"',
                "cliente": "ISMAEL MÉRIDA ARQUITECTURA",
                "servicio": "INGENIERÍA DE INSTALACIONES ",
                "fecha": "2017-2018",
                "imagen": "https://citmco.com.mx/img/bulldozer-1934464_1920.jpg"
            },
            {
                "titulo": 'INGENIERÍA DE INSTALACIONES PARA EL PROYECTO RESIDENCIAL “LAS MESAS”',
                "cliente": "AMA ARQUITECTOS",
                "servicio": "INGENIERÍA DE INSTALACIONES, COORDINACIÓN BIM",
                "fecha": "2019",
                "imagen": "https://citmco.com.mx/img/bulldozer-4657976_1920.jpg"
            },
            {
                "titulo": "PROGRAMA XPANDE DE LA CÁMARA DE COMERCIO DE MÁLAGA",
                "cliente": "RED ELÉCTRICA DE ESPAÑA",
                "servicio": "CONSULTORÍA BIM",
                "fecha": "2019",
                "imagen": "https://citmco.com.mx/img/bulldozer-2195329_1920.jpg"
            },
            {
                "titulo": "PROYECTO DE DIGITALIZACIÓN DE INVENTARIO DE CARRETERAS",
                "cliente": "DEPARTAMENT DE TERRITORI I SOSTENIBILITAT",
                "servicio": "DESARROLLO BIM",
                "fecha": "2019",
                "imagen": "https://citmco.com.mx/img/bulldozer-4045041_1920.jpg"
            },
            {
                "titulo": "LEVANTAMIENTO BIM AS-DESIGN DE CENTRO LOGÍSTICO PARA PROLOGIS",
                "cliente": "NORTON ",
                "servicio": "MODELADO Y COORDINACIÓN BIM DE TODAS LAS DISCIPLINAS ",
                "fecha": "2016",
                "imagen": "https://citmco.com.mx/img/bulldozer-4578216_1920.jpg"
            },
            {
                "titulo": 'INGENIERÍA DE INSTALACIONES PARA EL PROYECTO RESIDENCIAL “GREEN PARK"',
                "cliente": "ISMAEL MÉRIDA ARQUITECTURA",
                "servicio": "INGENIERÍA DE INSTALACIONES ",
                "fecha": "2017-2018",
                "imagen": "https://citmco.com.mx/img/bulldozer-4657976_1920.jpg"
            },
            {
                "titulo": 'INGENIERÍA DE INSTALACIONES PARA EL PROYECTO RESIDENCIAL “LAS MESAS”',
                "cliente": "AMA ARQUITECTOS",
                "servicio": "INGENIERÍA DE INSTALACIONES, COORDINACIÓN BIM",
                "fecha": "2019",
                "imagen": "https://citmco.com.mx/img/bulldozer-5558681_1920.jpg"
            },
            {
                "titulo": "PROGRAMA XPANDE DE LA CÁMARA DE COMERCIO DE MÁLAGA",
                "cliente": "RED ELÉCTRICA DE ESPAÑA",
                "servicio": "CONSULTORÍA BIM",
                "fecha": "2019",
                "imagen": "https://citmco.com.mx/img/construction-1491693_1920.jpg"
            },
            {
                "titulo": "PROYECTO DE DIGITALIZACIÓN DE INVENTARIO DE CARRETERAS",
                "cliente": "DEPARTAMENT DE TERRITORI I SOSTENIBILITAT",
                "servicio": "DESARROLLO BIM",
                "fecha": "2019",
                "imagen": "https://citmco.com.mx/img/construction-15759.jpg"
            }
        ]
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Card style={{ width: '100%', border: 'none' }}>
                            <Card.Body>
                                <h1 style={{ fontWeight: "initial", color:"#0033FF" }}>PROYECTOS</h1>
                                <Card.Title style={{ fontWeight: "bold", color: "#222" }}>En CITMCO desarrollamos alternativas que hagan los proyectos más rentables.</Card.Title>
                                <br />
                                <Row>
                                {
                                    jsonProjects.Proyectos.map((proyecto, i) => {
                                        return (
                                            <>
                                                {
                                                    <Col sm={4}>
                                                    <Card style={{ width: '100%', border: 'none' }}>
                                                            <Card.Img style={{ width: '100%', height: '210px' }} variant="top" src={proyecto.imagen} />
                                                            <Card.Body className="fade-in" style={{ textAlign: "left" }}>
                                                                <Card.Title>{proyecto.titulo}</Card.Title>
                                                                <Card.Subtitle className="mb-2 text-muted"><span style={{ fontWeight: "bold", color: "#1f33a1" }}>CLIENTE: </span>{proyecto.cliente}</Card.Subtitle>
                                                                <Card.Subtitle className="mb-2 text-muted"><span style={{ fontWeight: "bold", color: "#1f33a1" }}>SERVICIOS: </span>{proyecto.servicio}</Card.Subtitle>
                                                                <Card.Subtitle className="mb-2 text-muted"><span style={{ fontWeight: "bold", color: "#1f33a1" }}>FECHA: </span>{proyecto.fecha}</Card.Subtitle>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                }
                                            </>
                                        );
                                    })
                                }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}